import { mapGetters } from 'vuex';

export default {
	data () {
		return {
			selectedThemes: [],
			newTheme: {
				title: ''
			},
			errorMessages: []
		}
	},
	computed: {
		...mapGetters({
			themes: ['theme/themes'],
			isLoadingResources: 'isLoadingResources'
		})
	},
	created () {
		this.$store.dispatch('theme/fetchThemes')
	},
	methods: {
		deleteThemes () {
			if(this.selectedThemes.length > 0) {
				this.$store.dispatch('theme/deleteThemes', this.selectedThemes).then(() => {
					this.$notify({
						title: this.selectedThemes.length > 1 ? 'Thema\'s zijn verwijderd' : 'Thema is verwijderd',
						message: this.selectedThemes.length > 1 ? 'Thema\'s zijn succesvol verwijderd!' : 'Thema is succesvol verwijderd!',
						type: 'success',
						position: 'bottom-right'
					});
					this.selectedThemes = []
				})
			}
		},
		createTheme () {
			this.$store.dispatch('theme/createTheme', this.newTheme).then(() => {
				this.errorMessages = []
				this.newTheme.title = '';
				this.$notify({
					title: 'Thema toegevoegd',
					message: 'Thema is succesvol aangemaakt!',
					type: 'success',
					position: 'bottom-right'
				});
			}).catch((errors) => {
				this.errorMessages = errors
			});
		},
		toggleSelectedTheme (selectedId) {
			if(this.selectedThemes.includes(selectedId)) {
				this.selectedThemes = this.selectedThemes.filter(id => id !== selectedId)
			} else {
				this.selectedThemes.push(selectedId)
			}
		}
	}
}